@font-face {
  font-family: 'Magic';
  src: url(./assets/fonts/Supercell-Magic.woff) format("woff"), url(./assets/fonts/Supercell-Magic.woff2) format("woff2");
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#deck {
  filter: contrast(0);
  min-height: 200px;
}

#deck.started {
  filter: contrast(1);
}

.board button {
  padding:0;
  /* height: "24px"; */
  border-radius: 0;
  background: #A4C24A;
  border:0;

}
.board button:nth-child(2n+1) {
background: #8DAB45;
}
.card {
  border: "10px solid red"
}